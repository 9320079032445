import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import { CategoryWithChildren } from '@components/category/domain';

import { pricingTier } from '@data/pricing';

import { formatCurrency } from '@lib/format';

type CategoryCardProps = {
  cat: CategoryWithChildren;
  formik: ReturnType<typeof useFormik>;
};

const CategoryCard: FC<CategoryCardProps> = ({ cat, formik }) => {
  const active = formik.values.interests.some((it: string) => it === cat.id);

  return (
    <div
      className={clsx(
        'flex flex-col cursor-pointer rounded bg-background-avg text-xs relative ',
        'px-7 py-10 w-56 h-80 min-w-[200px]',
        active
          ? 'border-interaction-enabled border-11/2'
          : 'border-background-dark border-5/2 hover:border-interaction-statusDots'
      )}
      style={
        active
          ? {
              padding: 'calc(2.5rem - 3px) calc(1.75rem - 3px)',
            }
          : {}
      }
      onClick={() =>
        formik.setFieldValue(
          'interests',
          active
            ? formik.values.interests.filter((it: string) => it !== cat.id)
            : [...formik.values.interests, cat.id]
        )
      }
    >
      {active && (
        <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-interaction-enabled h-8 w-8 rounded-full flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
      <p className="font-emp font-bold text-lg mt-0 mb-2 whitespace-nowrap">
        {cat.name}
      </p>
      {/*<div className="font-emp font-bold text-interaction-disabled whitespace-nowrap">*/}
      {/*  <span className="text-xl text-white mr-1">*/}
      {/*    {formatCurrency(pricingTier.price / 100, pricingTier.currency, false)}*/}
      {/*  </span>{' '}*/}
      {/*  /month*/}
      {/*</div>*/}
      <hr className="bg-interaction-enabled opacity-70 -mx-1 my-3 h-0.5" />
      <div className="flex-1">
        {cat.children.map((subCat) => (
          <p key={subCat.id} className="mb-2">
            · {subCat.name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CategoryCard;
