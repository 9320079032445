import { NOOP } from '@lib';
import { useEffect, useState } from 'react';

import { COUNTRY_MAP } from '@data/countries';

export function useUserCountry() {
  const [country, setCountry] = useState<string>(undefined);

  useEffect(() => {
    let cancelled = false;

    (async () => {
      try {
        const res = await (
          await fetch('https://extreme-ip-lookup.com/json/')
        ).json();
        if (cancelled) return;
        if (res.countryCode && res.countryCode !== '') {
          setCountry(res.countryCode);
          return;
        }
      } catch {
        try {
          const res = await (await fetch('http://ip-api.com/json')).json();
          if (cancelled) return;
          if (res.countryCode && res.countryCode !== '') {
            setCountry(res.countryCode);
            return;
          }
        } catch {
          NOOP();
        }
      }
    })();

    return () => {
      cancelled = true;
    };
  }, []);

  return country ? COUNTRY_MAP.get(country) : undefined;
}
