import { useApolloClient, useMutation } from '@apollo/client';
import { getErrorMessages, getInvalidFields, NOOP } from '@lib';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';
import { ArraySchema, StringSchema } from 'yup';

import { SignupDocument } from '@redsleeve/oilynx-domain';

import { ACCOUNT_TYPES } from '@components/AccountTypeCard';

import { countryValues, mapCountryToSelectValue } from '@data/countries';

import { useUserCountry } from '@lib/hooks/useUserCountry';

import Step1 from './Step1';
import Step2 from './Step2';

export type ActivateAccountFormProps = {
  token: string;
  siteUrl: string;
};

export const ActivateAccountForm: FC<ActivateAccountFormProps> = ({
  token,
  siteUrl,
}) => {
  const apolloClient = useApolloClient();
  const [signup] = useMutation(SignupDocument);
  const [step, setStep] = useState(1);
  const userCountry = useUserCountry();

  const step1Formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
      country: countryValues.find((it) => it.value.isoCode === 'CH'),
      phoneNumber: '',
      companyName: '',
      companyAddress: '',
      companyActivity: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters.')
        .max(128, 'Password must be shorter than 128 characters.')
        .matches(/[a-z]+/, 'Password should include a lowercase letter.')
        .matches(/[A-Z]+/, 'Password should include an uppercase letter.')
        .matches(/[0-9]+/, 'Password should include a number.')
        .required('Please choose a password to protect your account.'),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match.'
      ),
      country: Yup.object().required('This field is required.'),
      phoneNumber: Yup.string()
        .matches(
          /^\+?(?:[0-9] ?){6,14}[0-9]$/,
          'Please provide a valid phone number.'
        )
        .required('This field is required.'),
      companyName: Yup.string().required('This field is required.'),
      companyAddress: Yup.string().required('This field is required.'),
      companyActivity: Yup.string().required('This field is required.'),
    }),
    onSubmit: async () => {
      setStep(2);
    },
  });

  const step1FormikRef = useRef(step1Formik);
  useUpdateRefIfShallowNew(step1FormikRef, step1Formik);

  useEffect(() => {
    if (step1Formik.values.country) {
      step1FormikRef.current.setFieldValue(
        'phoneNumber',
        step1Formik.values.country.value.dialCode
      );
    }
  }, [step1FormikRef, step1Formik.values.country]);

  useEffect(() => {
    if (
      (!step1Formik.values.country ||
        step1Formik.values.country === step1Formik.initialValues.country) &&
      userCountry
    ) {
      step1Formik.setFieldValue(
        'country',
        mapCountryToSelectValue(true, userCountry)
      );
    }
  }, [userCountry]);

  const step2Formik = useFormik({
    initialValues: {
      interests: [],
      accountDescription: '',
      accountType: '',
    },
    validationSchema: Yup.object({
      accountType: Yup.string().required('Please select an option'),
      accountDescription: Yup.string().when(
        'accountType',
        (accountType: any, schema: StringSchema) => {
          return accountType === ACCOUNT_TYPES[2].id
            ? schema.required('Please specify your job role')
            : schema;
        }
      ),
      interests: Yup.array().when(
        'accountType',
        (accountType: string, schema: ArraySchema) => {
          return accountType !== ACCOUNT_TYPES[2].id
            ? schema
                .min(1, 'Please select at least a category.')
                .required('Required')
            : schema;
        }
      ),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        const { data } = await signup({
          variables: {
            user: {
              token,
              password: step1Formik.values.password,
              country: step1Formik.values.country.value.isoCode,
              phoneNumber: step1Formik.values.phoneNumber,
              companyName: step1Formik.values.companyName,
              companyAddress: step1Formik.values.companyAddress,
              companyActivity: step1Formik.values.companyActivity,
              interests: values.interests,
              accountTypes: [values.accountType],
              roleDescription: values.accountDescription,
            },
            origin: siteUrl,
          },
        });
        // success

        localStorage.setItem('x-token', data.signup.accessToken);
        localStorage.setItem('x-refresh-token', data.signup.refreshToken);

        await navigate(`/`);
        apolloClient.resetStore().catch(NOOP); // ignoring errors here because the validateToken query will fail
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) {
          if (
            Object.keys(formError).some(
              (field) => field !== 'interests' && field !== 'accountTypes'
            )
          ) {
            //we have errors is the previous step
            setStep(1);
          }
          setErrors(formError);
        }

        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else console.error('[ActivateAccountForm] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const preselectedInterestsFromPricingPage = JSON.parse(
      localStorage.getItem('preselected-interests') ?? '[]'
    );
    step2Formik.setFieldValue('interests', preselectedInterestsFromPricingPage);
  }, []);

  return (
    <>
      {step === 1 ? (
        <Step1 formik={step1Formik} />
      ) : (
        <Step2 formik={step2Formik} />
      )}
    </>
  );
};
