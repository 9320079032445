import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import SelectInput, { SelectInputProps } from '@components/form/SelectInput';
import TextInput, { TextInputProps } from '@components/form/TextInput';

import './SelectAndTextInput.css';

type SelectAndTextInputProps = {
  className?: string;

  formik: ReturnType<typeof useFormik>;
  label: string;
  select: Omit<SelectInputProps, 'formik' | 'label'>;
  text: Omit<TextInputProps, 'formik' | 'label'>;

  showErrors?: boolean;
};

const SelectAndTextInput: FC<SelectAndTextInputProps> = ({
  className,
  formik,
  label,
  select,
  text,

  showErrors = true,
}) => {
  return (
    <div className="SelectAndTextInput-wrapper">
      <SelectInput
        className={className}
        formik={formik}
        {...select}
        label={label}
        showErrors={false}
      >
        <TextInput
          formik={formik}
          {...text}
          showError={false}
          className={clsx(text.className, 'flex-1')}
          innerClassName={clsx(text.innerClassName, 'h-full')}
        />
      </SelectInput>

      {showErrors &&
      formik.touched[select.name] &&
      formik.errors[select.name] ? (
        <div className="text-error">{formik.errors[select.name]}</div>
      ) : null}
      {showErrors && formik.touched[text.name] && formik.errors[text.name] ? (
        <div className="text-error">{formik.errors[text.name]}</div>
      ) : null}
    </div>
  );
};

export default SelectAndTextInput;
