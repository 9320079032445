import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useState } from 'react';

import Button from '@components/Button';
import SelectAndTextInput from '@components/form/SelectAndTextInput';
import { SelectInputValue } from '@components/form/SelectInput';
import TextInput, { PasswordEndPiece } from '@components/form/TextInput';

import { countryValues } from '@data/countries';

type Step1Props = {
  formik: ReturnType<typeof useFormik>;
};

const Step1: FC<Step1Props> = ({ formik }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <form onSubmit={formik.handleSubmit} className="flex-1 flex flex-col mb-4">
      <div className="flex items-center justify-center">
        <svg
          className="h-4 mr-1 text-trade-mine"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 20"
          fill="currentColor"
        >
          <rect
            x="2"
            y="2"
            width="36"
            height="16"
            fillRule="evenodd"
            clipRule="evenodd"
            ry="16"
            rx="8"
          />
        </svg>
        <svg
          className="h-4 mr-4 text-interaction-disabled"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16z"
            clipRule="evenodd"
          />
        </svg>
        <span className="opacity-70">Step 1 of 2</span>
      </div>
      <p className="font-emp font-bold text-xl mt-6 mb-0">
        Contact Information
      </p>
      <p className="opacity-70 text-xs mb-6">
        Complete your profile and help us verify your identity
      </p>
      <div className="text-left max-w-xs mx-auto">
        <TextInput
          formik={formik}
          name="password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          tooltip={
            <span>
              Password must be at least 8 characters long.
              <br />
              It should include a lower case letter (a-z),
              <br />
              an upper case letter (A-Z),
              <br />
              and a digit (0-9).
            </span>
          }
          placeholder={
            showPassword ? 'eg: mySecretPass123' : 'eg: ***************'
          }
          endPiece={<PasswordEndPiece setShowPassword={setShowPassword} />}
        />

        <TextInput
          formik={formik}
          name="passwordConfirmation"
          type={showPassword ? 'text' : 'password'}
          label="Confirm Password"
          placeholder={
            showPassword ? 'eg: mySecretPass123' : 'eg: ***************'
          }
          className="mt-3"
          endPiece={<PasswordEndPiece setShowPassword={setShowPassword} />}
        />

        <SelectAndTextInput
          formik={formik}
          label="Phone Number"
          className="mt-3"
          select={{
            name: 'country',
            values: countryValues,
            reactSelectProps: {
              menuPlacement: 'top',
              getOptionLabel: (countryValue: SelectInputValue) =>
                countryValue.value?.name ?? '',
            },
          }}
          text={{
            name: 'phoneNumber',
            type: 'text',
          }}
        />

        <TextInput
          formik={formik}
          name="companyName"
          label="Company Name"
          placeholder="eg: Redsleeve SA"
          className="mt-3"
        />

        <TextInput
          formik={formik}
          name="companyAddress"
          label="Company Address"
          placeholder="eg: Route de Florissant 47BIS, Genève, Switzerland"
          className="mt-3"
        />

        <TextInput
          formik={formik}
          name="companyActivity"
          label="Company Activity"
          placeholder="eg: Oil trading"
          className="mt-3"
        />
      </div>
      <div className="hidden lg:flex justify-center mt-12">
        <Button
          variant="outlined"
          className="flex ring-2 ring-interaction-enabled items-center max-w-xs"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </Button>
      </div>

      <div className="flex-1 lg:hidden" />
      <div className="flex lg:hidden justify-center">
        <Button
          variant="primary"
          className="w-full"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default Step1;
