import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import AccountTypeCard, { ACCOUNT_TYPES } from '@components/AccountTypeCard';
import Button from '@components/Button';
import CategoryCard from '@components/category/CategoryCard';
import TextInput from '@components/form/TextInput';

import { useCategories } from '@lib/hooks/useOrderedCategories';

type Step2Props = {
  formik: ReturnType<typeof useFormik>;
};

const Step2: FC<Step2Props> = ({ formik }) => {
  const { groupedCategories } = useCategories();

  return (
    <form onSubmit={formik.handleSubmit} className="flex-1 flex flex-col mb-4">
      <div className="flex items-center justify-center">
        <svg
          className="h-4 mr-1 text-trade-mine"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16z"
            clipRule="evenodd"
          />
        </svg>
        <svg
          className="h-4 mr-4 text-trade-mine"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 20"
          fill="currentColor"
        >
          <rect
            x="2"
            y="2"
            width="36"
            height="16"
            fillRule="evenodd"
            clipRule="evenodd"
            ry="16"
            rx="8"
          />
        </svg>
        <span className="opacity-70">Step 2 of 2</span>
      </div>
      <p className="font-emp font-bold text-xl mt-6 mb-0">Are you a ...?</p>
      <div>
        <div className="flex space-x-6 lg:justify-center text-left overflow-x-auto p-4">
          {ACCOUNT_TYPES.map((accountType) => (
            <AccountTypeCard
              key={accountType.id}
              accountType={accountType}
              formik={formik}
            />
          ))}
        </div>

        {formik.touched.accountType && formik.errors.accountType ? (
          <div className="text-error">{formik.errors.accountType}</div>
        ) : null}
      </div>
      {(formik.values.accountType === ACCOUNT_TYPES[0].id ||
        formik.values.accountType === ACCOUNT_TYPES[1].id) && (
        <>
          <p className="font-emp font-bold text-xl mt-6 mb-0">
            Please select the products you work with
          </p>
          {/*<p className="opacity-70 text-xs mb-6">*/}
          {/*  We offer a 90 day trial for all our products*/}
          {/*</p>*/}
          <div>
            <div className="flex space-x-6 lg:justify-center text-left overflow-x-auto p-4">
              {groupedCategories.map((cat) => (
                <CategoryCard key={cat.id} cat={cat} formik={formik} />
              ))}
            </div>

            {formik.touched.interests && formik.errors.interests ? (
              <div className="text-error">{formik.errors.interests}</div>
            ) : null}
          </div>
        </>
      )}
      {formik.values.accountType === ACCOUNT_TYPES[2].id && (
        <>
          <div className="text-left max-w-xs mx-auto">
            <TextInput
              formik={formik}
              name="accountDescription"
              label="Other *"
              placeholder="eg: Analysts"
              className="mt-3"
            />
          </div>
        </>
      )}
      <div className="hidden lg:flex justify-center mt-12">
        <Button
          variant="outlined"
          className="flex ring-2 ring-interaction-enabled items-center max-w-xs"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Finish
        </Button>
      </div>

      <div className="flex-1 lg:hidden" />
      <div className="flex lg:hidden justify-center">
        <Button
          variant="primary"
          className="w-full"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Finish
        </Button>
      </div>
    </form>
  );
};

export default Step2;
