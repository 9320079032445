import { useQuery } from '@apollo/client';
import Layout from '@layouts';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { FC } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { ValidateSignupTokenDocument } from '@redsleeve/oilynx-domain';

import { ActivateAccountForm } from './ActivateAccountForm';

const ActivateAccountPage: FC = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query);
  const [token] = useQueryParam('token', StringParam);
  const { data: validateTokenData, error: validateTokenError } = useQuery(
    ValidateSignupTokenDocument,
    {
      skip: !token,
      variables: {
        token,
      },
    }
  );

  if (validateTokenError) {
    // show error modal and send to signup
    console.error(
      '[ActivateAccountPage] validateTokenError',
      validateTokenError
    );
  }

  return (
    <Layout url="/activate-account/" title="Activate account" disableIndex>
      <div className="flex-1 flex flex-col items-center lg:justify-center text-center">
        <div className="lg:py-10 w-full min-h-screen flex flex-col">
          <h1 className="text-base lg:text-5xl font-emp mt-4 lg:mt-0 mb-12 lg:mb-4">
            Registration
          </h1>

          {validateTokenData ? (
            <ActivateAccountForm token={token} siteUrl={siteMetadata.siteUrl} />
          ) : validateTokenError ? (
            <div>
              Token is not valid. You can try to{' '}
              <Link to={'/login'}>login</Link> or{' '}
              <Link to={'/signup'}>request another token</Link>.
            </div>
          ) : (
            <p>Validating token...</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
  query SiteMetadataQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default ActivateAccountPage;
